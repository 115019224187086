import { Hero, Card, Button } from "react-daisyui";
import React from "react";
import CDWLogo from "../assets/img/CDWLogo.svg";
import AzureOpenAILogo from "../assets/img/azureopenai-logo.png";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className="App">
            <div className="flex flex-col items-center justify-center pt-5">
                <img
                    src={CDWLogo}
                    alt="CDW Logo"
                    width={112}
                    height={112}
                    className="w-28 mx-auto mb-2"
                />
                <h1 className="lg:text-5xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                    Azure AI Services Demos
                </h1>
            </div>
            <Hero className="flex flex-col mt-5 p-16 bg-slate-300 shadow-lg rounded">
                <Hero.Content className="flex lg:flex-row-reverse max-w-full">
                    <div className="w-2/6 flex flex-col">
                        <Card
                            className="shadow-2xl bg-base-100 w-full h-64"
                            bordered="false"
                        >
                            <Card.Body className="p-8 mx-auto flex justify-center items-center">
                                <img
                                    src={AzureOpenAILogo}
                                    alt="Azure OpenAI Logo"
                                    className=" mx-auto"
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="text-center lg:text-left w-3/6">
                        <h2 className="text-4xl font-bold">
                            <span className="underline decoration-red-700 decoration-4">
                                Real World{" "}
                            </span>
                            Generative AI: Use Case Demos
                        </h2>
                        <p className="py-3">
                            Our team has created two exciting demos showcasing the power of
                            generative AI on Azure OpenAI. The first demo is an omni-channel
                            intelligence use case, which allows businesses to derive valuable
                            insights from customer messages, regardless of the channel they
                            were sent through. The second demo is a product description
                            generator, where technical specs for a product are inputted, and
                            GPT-4 generates a compelling retail product description. These
                            demos demonstrate the potential of Azure OpenAI to revolutionize
                            the way businesses interact with their customers and showcase
                            their products.
                        </p>
                    </div>
                </Hero.Content>
            </Hero>
            <div className="p-5">
                <h1 className="lg:text-5xl font-bold sm:text-lg md:text-3xl font-sans capitalize">
                    Choose a Demo
                </h1>
                <div className="grid grid-cols-2 gap-6 p-10 w-5/6 mx-auto">
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    OmniChannel Intelligence
                                </h2>
                                <p className="py-3">
                                    The omni-channel Intelligence demo showcases the power of Azure
                                    OpenAI to derive valuable insights from customer messages,
                                    regardless of the channel they were sent through. This demo
                                    demonstrates the potential of Azure OpenAI to revolutionize
                                    the way businesses interact with their customers.
                                </p>
                                <Link to="/omnichannel">
                                    <Button className="btn btn-primary text-white">
                                        Go to OmniChannel
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Product Description Generator
                                </h2>
                                <p className="py-3">
                                    Azure OpenAI GPT-4 is a powerful tool that can transform dull
                                    technical specifications into engaging and persuasive
                                    retail-ready compositions. By providing the large language
                                    models with technical specifications, they can generate
                                    captivating product descriptions that highlight
                                    customer-centric benefits and evoke emotional connections.
                                </p>
                                <Link to="/product-description">
                                    <Button className="btn btn-primary text-white">
                                        Go to Product Description
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Financial Services Bot
                                </h2>
                                <p className="py-3">
                                    Generative AI LLMs can be used for MRKL to aide in the
                                    financial services industry by providing various solutions
                                    such as content generation and summarization for financial
                                    reports, newsletters, and marketing campaigns. They can also
                                    be used for data analysis and forecasting for financial
                                    trends, risks, and opportunities. Additionally, natural
                                    language processing and understanding can be used for customer
                                    service, compliance, and sentiment analysis. Robotic process
                                    automation can also be used for streamlining workflows,
                                    reducing errors, and increasing efficiency.
                                </p>
                                <Link to="https://nc-azure-financial-chainlit.azurewebsites.net/">
                                    <Button className="btn btn-primary text-white">
                                        Go to Financial Services Bot
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Retrieval-Augmented Generation
                                </h2>
                                <p className="py-3">
                                    Retrieval augmented generation (RAG) is a powerful technique
                                    that can be used to create bots and automate processes with
                                    LLMs and Azure OpenAI. RAG combines the power of large
                                    language models with the precision of database retrieval to
                                    generate responses grounded in relevant information. This
                                    technique can be used to create chatbots that can answer
                                    questions and provide information to users in a natural and
                                    conversational manner. Additionally, RAG can be used to
                                    automate processes such as data analysis, content generation,
                                    and customer service, reducing the need for manual
                                    intervention and increasing efficiency.
                                </p>
                                <Link to="https://nrf-azure.intelligentcx.io/">
                                    <Button className="btn btn-primary text-white">
                                        Go to RAG Demo
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Azure Text-To-Speech Avatar
                                </h2>
                                <p className="py-3">
                                    Combining generative AI with Azure OpenAI GPT-4 and the Azure
                                    Text to Speech Avatar can revolutionize customer engagement.
                                    With the power of generative AI, businesses can create
                                    personalized and engaging content for their customers. Azure
                                    OpenAI GPT-4 can generate natural language responses based on
                                    data and queries, while the Azure Text to Speech Avatar can
                                    bring these responses to life with realistic lip-syncing and
                                    voice synthesis. This combination can create exceptional and
                                    engaging interactions for customers, building conversational
                                    agents, virtual assistants, and chatbots that can provide
                                    information, answer questions, and assist with tasks in a
                                    natural and intuitive manner.
                                </p>
                                <Link to="https://avatar.intelligentcx.io/">
                                    <Button className="btn btn-primary text-white">
                                        Goto the Azure TTS Avatar Demo
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Azure Chat
                                </h2>
                                <p className="py-3">
                                    Give your employees access to retrieval-augmented generation by using Azure Chat. 
                                    Azure Chat allows for users to interact with GPT-3.5 Turbo or GPT-4. They also 
                                    create seperate sessions and chat with individual documents. Each users
                                    can create their own document sessions to ask questions about a given document.
                                </p>
                                <Link to="https://azurechat.intelligentcx.io/">
                                    <Button className="btn btn-primary text-white">
                                        Goto the Azure Chat
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;
