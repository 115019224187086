import { Modal, Button } from "react-daisyui";

const ProductDetail = ({ open, toggleModal, details }) => {
    return (
        <Modal open={open}>
            <Modal.Body className="w-96">
            </Modal.Body>
            <Modal.Actions>
                <Button onClick={toggleModal}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ProductDetail;
