import "../App.css";
import ProductCard from "../components/OmniChannel/ProductCard";
import { useState } from "react";
import Title from "../components/OmniChannel/Title";
import HeroSection from "../components/OmniChannel/Hero";
import ToastAlert from "../components/Toast";
import ProductDetails from "../components/OmniChannel/ProductDetails";

function OmniChannel() {
    const [data, setData] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [messageModal, setSetMessageModal] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({});

    const handleMessageDetails = (index) => {
        setCurrentMessage(data[index]);
        setSetMessageModal(!messageModal);
    }

    return (
        <>
        { currentMessage && <ProductDetails open={messageModal} toggleModal={handleMessageDetails} details={currentMessage} />}

            <ToastAlert alerts={alerts} setAlerts={setAlerts} />
            <Title />
            <HeroSection setData={setData} alerts={alerts} setAlerts={setAlerts} />
            <div className="w-full mx-auto mt-5 md:w-11/12">
                <div className="grid grid-cols-3 gap-5 mb-10">
                    {data.map((item, item_id) => (
                        <ProductCard key={item_id} item_id={item_id} item={item} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default OmniChannel;
