import CDWLogo from '../../assets/img/CDWLogo.svg'

const ChatTitle = ({status}) => {

    return (
        <div className="flex flex-col items-center justify-center pt-5">
            <img src={CDWLogo} alt="CDW Logo" width={112} height={112} className="w-28 mx-auto mb-2" />
            <h1 className="lg:text-5xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                Product Description Generator
            </h1>
        </div>
    )
};

export default ChatTitle;