import { Hero, FileInput, Card, Form, Button, Progress } from "react-daisyui";
import { useRef, useState, useCallback, useEffect } from "react";
import { RemoteRunnable } from "langchain/runnables/remote";

const HeroSection = ({ setData, setAlerts }) => {
    const fileInput = useRef(null);
    const count = useRef(0);
    const max = useRef(0);
    const [processing, setProcessing] = useState(false);
    const remoteChain = useRef();

    useEffect(() => {
        remoteChain.current = new RemoteRunnable({
            url: "https://azuredemos.intelligentcx.io/omni/",
        });
    }, []);

    const handleSetAlerts = useCallback((alert) => {
        setAlerts((prev) => [...prev, alert]);
    }, [setAlerts]);

    const makeQuery = async (email) => {
        let response = await remoteChain.current.invoke({
            product: JSON.stringify(email),
        });
        return response;
    };

    const handleFileUpload = useCallback(async (e) => {
        e.preventDefault();
        const file = fileInput.current.files[0];
        if (file) {
            if (!file.name.endsWith(".json")) {
                handleSetAlerts({
                    type: "error",
                    message: "Invalid file type. Please select a JSON file.",
                });
                return;
            }
            setData([]);
            setProcessing(true);
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async (evt) => {
                const json = JSON.parse(evt.target.result);
                max.current = json.length;
                for (let i = 0; i < json.length; i++) {
                    let message = json[i].marketing_email;
                    let response = await makeQuery(message);
                    try {
                        let jsonResponse = JSON.parse(response.content);

                        count.current += 1;
                        setData((prev) => [...prev, jsonResponse]);
                    } catch (error) {
                        console.log(error);
                    }
                }
                setProcessing(false);
            };
        } else {
            handleSetAlerts({ type: "error", message: "No file selected" });
        }
    }, [handleSetAlerts, setData]);

    return (
        <Hero className="flex flex-col mt-5 p-16 bg-slate-300 shadow-lg rounded">
            <Hero.Content className="flex lg:flex-row-reverse max-w-full">
                <div className="w-2/6 flex flex-col">
                    <Card className="shadow-2xl bg-base-100 w-full h-64" bordered="false">
                        <Card.Body className="p-5">
                            <Form>
                                <Form.Label title="Upload Files" className="font-bold" />
                                <div className="flex flex-col items-center">
                                    <FileInput
                                        className="mb-4 w-4/5"
                                        color="primary"
                                        ref={fileInput}
                                    />
                                    <Button
                                        rounded="true"
                                        onClick={handleFileUpload}
                                        className="inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out"
                                        color="success"
                                    >
                                        Submit
                                    </Button>
                                </div>
                                <div className="flex flex-col mx-auto">
                                    <Progress
                                        value={count.current}
                                        max={max.current}
                                        color="secondary"
                                        className="mt-5"
                                    />
                                    {processing && (
                                        <span>
                                            {count.current} of {max.current} emails processed
                                        </span>
                                    )}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="text-center lg:text-left w-3/6">
                    <h2 className="text-4xl font-bold">
                        Harness the power of{" "}
                        <span className="underline decoration-red-700 decoration-4">
                            Generative AI:
                        </span>{" "}
                        for OmniChannel Intelligence
                    </h2>
                    <p className="py-3">
                        Azure OpenAI Service provides customers with advanced language AI
                        capabilities, including OpenAI GPT-4, GPT-3, Codex, and DALL-E
                        models. With Azure OpenAI, customers can enjoy the full security
                        capabilities of Microsoft Azure, combined with the power and
                        versatility of OpenAI's models. This powerful combination can be
                        used to create omnichannel intelligence, allowing businesses to
                        analyze and understand customer interactions across multiple
                        channels and provide personalized and seamless experiences. Whether
                        it's through chatbots, voice assistants, or other forms of
                        communication, Azure OpenAI can help businesses deliver intelligent
                        and engaging customer experiences.
                    </p>
                    <p>
                        <a href="/emails.json">Click here</a> to download the input file.
                    </p>
                </div>
            </Hero.Content>
        </Hero>
    );
};

export default HeroSection;
