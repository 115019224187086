import { Modal, Button } from "react-daisyui";
import Markdown from 'react-markdown'

const ProductDetail = ({ open, toggleModal, details }) => {
    console.log(details)
    return (
        <Modal open={open}>
            <Modal.Body className="w-96">
                {
                    details && details.length > 0 && details.map((detail, index) => (
                        <div key={index}>
                            <h1 className="text-lg font-bold">{detail.title}</h1>
                            <Markdown>{detail.content}</Markdown>
                        </div>
                    ))
                }
            </Modal.Body>
            <Modal.Actions>
                <Button onClick={toggleModal}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}


export default ProductDetail;