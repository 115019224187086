import { Hero, FileInput, Card, Form, Button, Progress } from "react-daisyui";
import { useRef, useState, useCallback, useEffect } from "react";
import { RemoteRunnable } from "langchain/runnables/remote";

const HeroSection = ({ setData, setAlerts }) => {
    const fileInput = useRef(null);
    const count = useRef(0);
    const max = useRef(0);
    const [processing, setProcessing] = useState(false);
    const remoteChain = useRef();

    const handleSetAlerts = useCallback(
        (alert) => {
            setAlerts((prev) => [...prev, alert]);
        },
        [setAlerts]
    );

    useEffect(() => {
        remoteChain.current = new RemoteRunnable({
            url: "https://azuredemos.intelligentcx.io/product",
        });
    }, []);

    const makeQuery = async (product) => {
        let response = await remoteChain.current.invoke({
            product: JSON.stringify(product),
        });

        return response;
    };

    const handleFileUpload = useCallback(
        async (e) => {
            e.preventDefault();
            const file = fileInput.current.files[0];
            if (file) {
                if (!file.name.endsWith(".json")) {
                    handleSetAlerts({
                        type: "error",
                        message: "Invalid file type. Please select a JSON file.",
                    });
                    return;
                }

                setData([]);
                setProcessing(true);
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = async (evt) => {
                    const json = JSON.parse(evt.target.result);
                    max.current = json.length;
                    for (let i = 0; i < json.length; i++) {
                        let message = json[i];
                        let response = await makeQuery(message);
                        try {
                            let jsonResponse = JSON.parse(response.content);
                            count.current += 1;
                            setData((prev) => [...prev, jsonResponse]);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    setProcessing(false);
                };
            } else {
                handleSetAlerts({ type: "error", message: "No file selected" });
            }
        },
        [handleSetAlerts, setData]
    );

    return (
        <Hero className="flex flex-col mt-5 p-16 bg-slate-300 shadow-lg rounded">
            <Hero.Content className="flex lg:flex-row-reverse max-w-full">
                <div className="w-2/6 flex flex-col">
                    <Card className="shadow-2xl bg-base-100 w-full h-64" bordered="false">
                        <Card.Body className="p-5">
                            <Form>
                                <Form.Label title="Upload Files" className="font-bold" />
                                <div className="flex flex-col items-center">
                                    <FileInput
                                        className="mb-4 w-4/5"
                                        color="primary"
                                        ref={fileInput}
                                    />
                                    <Button
                                        loading={processing}
                                        rounded="true"
                                        onClick={handleFileUpload}
                                        className="inline-block rounded-full px-6 text-md uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out"
                                        color="success"
                                        wide="true"
                                    >
                                        {" "}
                                        Submit
                                    </Button>
                                </div>
                                <div className="flex flex-col mx-auto">
                                    <Progress
                                        value={count.current}
                                        max={max.current}
                                        color="secondary"
                                        className="mt-5"
                                    />
                                    {processing && (
                                        <span>
                                            {count.current} of {max.current} products processed
                                        </span>
                                    )}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="text-center lg:text-left w-3/6">
                    <h2 className="text-4xl font-bold">
                        <span className="underline decoration-red-600 decoration-4">
                            From jargon to joy:{" "}
                        </span>
                        Transform dry specs into dazzling descriptions with Azure OpenAI.
                    </h2>
                    <p className="py-3">
                        Say goodbye to dull technical specifications that fail to engage
                        your customers. Embrace the power of Azure OpenAI GPT-4 and its
                        integrated generative AI ensemble! Our large language models,
                        skillfully directed by Azure OpenAI's processing prowess, transform
                        your technical language into retail-ready compositions of
                        persuasion. Provide them with specifications, and watch them
                        elegantly transform into captivating product descriptions that
                        highlight customer-centric benefits and evoke emotional connections.
                        Let Azure OpenAI's GPT-4 conductor turn your website into a
                        conversion choir, where every description sells, and your marketing
                        reaches new heights. Don't just present specifications, tell stories
                        that convert. Discover the Azure OpenAI GPT-4 advantage today.
                    </p>
                    <p>
                        <a href="/products.json">Click here</a> to download the input file.
                    </p>
                </div>
            </Hero.Content>
        </Hero>
    );
};

export default HeroSection;
