import { Card, Button } from "react-daisyui";


const ProductCard = ({ item, item_id, toggleModal }) => {
    console.log(item)

    return (
        <Card className="flex flex-col mt-5 shadow-xl overflow-auto h-auto">
            <Card.Title className="p-5">{item.product}</Card.Title>
            <Card.Body className="flex-grow h-auto">
                <ul className="list-none">
                    <li className="py-2 font-bold">{item.headline}</li>
                    <li className="py-2">{item.description}</li>
                </ul>
            </Card.Body>
            <Card.Actions className="flex justify-center py-5">
                <Button color="primary" className="text-white" onClick={() => { toggleModal(item_id) }}>
                    Product Details
                </Button>
            </Card.Actions>
        </Card>
    )
};

export default ProductCard;