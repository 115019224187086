import { Alert, Button, Toast } from "react-daisyui";



const ToastAlert = ({ alerts, setAlerts }) => {

    const removeAlert = (indexToRemove) => {
        setAlerts(alerts.filter((_, index) => index !== indexToRemove));
    };

    return (
        <Toast>
            {alerts.map((alert, index) => <Alert key={index} status={alert.type}>
                <div className="w-full flex-row justify-between gap-2">
                    <h3>{alert.message}</h3>
                </div>
                <Button color="ghost" onClick={() => removeAlert(index)} >
                    X
                </Button>
            </Alert>)}
        </Toast>
    )

};

export default ToastAlert;