import { Card, Button } from "react-daisyui";

const ProductCard = ({ item_id, item }) => {


    return (
        <Card className="flex flex-col mt-5 shadow-xl overflow-auto h-auto">
            <Card.Title className="h-2">{item.product}</Card.Title>
            <Card.Body className="flex-grow h-auto">
                <ul className="list-outside list-disc text-left	">
                    <li><span className="font-bold">Product:</span> {item.product}</li>
                    <li><span className="font-bold">Channel:</span> {item.channel}</li>
                    <li><span className="font-bold">Sentiment:</span> {item.sentiment}</li>
                    <li><span className="font-bold">Interaction:</span> {item.interaction}</li>
                </ul>
            </Card.Body>
            <Card.Actions className="flex justify-center py-5">
                <Button color="primary" className="text-white" onClick={() => { }}>
                    Original Message
                </Button>
            </Card.Actions>
        </Card>
    );
};

export default ProductCard;
