import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OmniChannel from './pages/OmniChannel';
import ProductDescription from './pages/ProductDescription';
import Home from './pages/Home';


import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/omnichannel" element={<OmniChannel />} />
          <Route path="/product-description" element={<ProductDescription />} />
        </Routes>
      </div>

    </Router>
  );
}

export default App;